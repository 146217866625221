import axios from 'axios'
import type { AxiosInstance } from 'axios'

const vestApiV2Obj: { vestApiV2?: AxiosInstance } = {}

export const useVestApiV2 = (): { vestApiV2: AxiosInstance } => {
  if (vestApiV2Obj.vestApiV2) {
    return {
      vestApiV2: vestApiV2Obj.vestApiV2,
    }
  }

  // Create axios instance
  const runtimeConfig = useRuntimeConfig()
  const vestApiV2 = axios.create({
    baseURL: runtimeConfig.public.apiBaseUrlV2 as string,
  })

  return {
    vestApiV2,
  }
}
