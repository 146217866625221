import payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY from "/usr/src/app/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU from "/usr/src/app/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM from "/usr/src/app/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y from "/usr/src/app/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8 from "/usr/src/app/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U from "/usr/src/app/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs from "/usr/src/app/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4 from "/usr/src/app/.nuxt/components.plugin.mjs";
import prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E from "/usr/src/app/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import piniaProcessPaginatedResponse_plugin_jFYkTzK5nI6a7LRpf3gLlWcbeh4Ezrpr3zyoTtulsPQ from "/usr/src/app/plugins/piniaProcessPaginatedResponse.plugin.ts";
import piniaVestApi_plugin_DIDtQD9kua2TPoAhnBs15AYM2LUUJgBX2nLYs2EDtgQ from "/usr/src/app/plugins/piniaVestApi.plugin.ts";
import sentry_plugin_R8gLBmp_VSrWPV6HvKXcfMH4nhVJZili0xCFUMWKYv4 from "/usr/src/app/plugins/sentry.plugin.ts";
import userRoutes_plugin_qCi7A_9mUP3H4CFreAIpIXXlJPNirkLb0PhY2eZBLI4 from "/usr/src/app/plugins/userRoutes.plugin.ts";
import vuetify_plugin_ErlMHGb0tRcfBYGXWhp7u94rW4gAtcLAnJa_6UgRc8c from "/usr/src/app/plugins/vuetify.plugin.ts";
export default [
  payload_plugin_1_bEQpMjikuQhbV8UJ0PxUqmSvPdmV1jDa5DURnKW4M,
  revive_payload_client_kG4vF4SWGlhgLJRKrmco1O_zuG41odAu7e9PNPCDOtY,
  unhead_k2P3m_ZDyjlr2mMYnoDPwavjsDN8hBlk9cFai0bbopU,
  router_GNCWhvtYfLTYRZZ135CdFAEjxdMexN0ixiUYCAN_tpw,
  payload_client_kSYcIMYUswSDcSGoGWqM0MO2T4MjOZsQFA1GU6fqohM,
  navigation_repaint_client_iZ4sbIDUOBlI58WRS1dxkRZJOsKNs1c7SCqRszdxr6Y,
  check_outdated_build_client__GMdmUuNCTKDee9GyDhHz84CJEYUp5_FenYjvgAxtj8,
  chunk_reload_client_90gdG_aWZaDfO85SQYWzwaOywz7zi0wBnFUoX0cT54U,
  plugin_vue3_CQ_pO3THrTGIeYc0dvC91V75hY8qpo9B_8yZzOW5SFs,
  components_plugin_z4hgvsiddfKkfXTP6M8M4zG5Cb7sGnDhcryKVM45Di4,
  prefetch_client_plc8Lsxh1X10IEwNhW7mxf0MabjBz64b9ODLi87nY5E,
  piniaProcessPaginatedResponse_plugin_jFYkTzK5nI6a7LRpf3gLlWcbeh4Ezrpr3zyoTtulsPQ,
  piniaVestApi_plugin_DIDtQD9kua2TPoAhnBs15AYM2LUUJgBX2nLYs2EDtgQ,
  sentry_plugin_R8gLBmp_VSrWPV6HvKXcfMH4nhVJZili0xCFUMWKYv4,
  userRoutes_plugin_qCi7A_9mUP3H4CFreAIpIXXlJPNirkLb0PhY2eZBLI4,
  vuetify_plugin_ErlMHGb0tRcfBYGXWhp7u94rW4gAtcLAnJa_6UgRc8c
]