import type { AxiosInstance } from 'axios'
import type { Pinia } from 'pinia'
declare module 'pinia' {
  export interface PiniaCustomProperties {
    vestApiV1: AxiosInstance
    vestApiV2: AxiosInstance
  }
}

export default defineNuxtPlugin(({ $pinia }) => {
  const { vestApiV1 } = useVestApiV1()
  const { vestApiV2 } = useVestApiV2()
  const pinia = $pinia as Pinia
  pinia.use(() => ({
    vestApiV1,
    vestApiV2,
  }))
})
