import axios from 'axios'
import type { AxiosInstance } from 'axios'

const vestApiV1Obj: { vestApiV1?: AxiosInstance } = {}

export const useVestApiV1 = (): { vestApiV1: AxiosInstance } => {
  if (vestApiV1Obj.vestApiV1) {
    return {
      vestApiV1: vestApiV1Obj.vestApiV1,
    }
  }

  // Create axios instance
  const runtimeConfig = useRuntimeConfig()
  const vestApiV1 = axios.create({
    baseURL: runtimeConfig.public.apiBaseUrlV1 as string,
  })

  return {
    vestApiV1,
  }
}
