
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as signInU3RKe25XwrIwJjVPD1EZWDlFiXfgirQeBj5SuRgedF0Meta } from "/usr/src/app/pages/signIn.vue?macro=true";
import { default as singInepyD_456wIXGKL5yWPptl5kt8MCDg1mLRpmjVz7_guiMQMeta } from "/usr/src/app/pages/singIn.vue?macro=true";
import { default as sign_45inKNWbqEw17jPqYfKSd2RQwFs_wnbvso7laXkwyemJwOsMeta } from "/usr/src/app/pages/sign-in.vue?macro=true";
import { default as _91_46_46_46all_933yhDtI6SSiKn8yl7YlTvlQjn7g8_DEtqvKazNTS8UtMMeta } from "/usr/src/app/pages/[...all].vue?macro=true";
import { default as _91postId_93PZU8uyIMzgLrwe_45vZk6iSbwEfCMwO2v11gM1ZKtfqagMeta } from "/usr/src/app/pages/boards/[postId].vue?macro=true";
import { default as _91userName_93_45olBXZTgvGSKOpNEUjmQLpwJnycUbZXRB8A__45njXSkQMeta } from "/usr/src/app/pages/profiles/[userName].vue?macro=true";
import { default as _91classifiedAdId_934TyvAKlPHDZInhiP9uEJd2CoVqfHZZfOobJ4AabaSLcMeta } from "/usr/src/app/pages/classifieds/[classifiedAdType]/[classifiedAdId].vue?macro=true";
export default [
  {
    name: "signIn",
    path: "/signIn",
    component: () => import("/usr/src/app/pages/signIn.vue")
  },
  {
    name: "singIn",
    path: "/singIn",
    component: () => import("/usr/src/app/pages/singIn.vue")
  },
  {
    name: "sign-in",
    path: "/sign-in",
    component: () => import("/usr/src/app/pages/sign-in.vue")
  },
  {
    name: "all",
    path: "/:all(.*)*",
    meta: _91_46_46_46all_933yhDtI6SSiKn8yl7YlTvlQjn7g8_DEtqvKazNTS8UtMMeta || {},
    component: () => import("/usr/src/app/pages/[...all].vue")
  },
  {
    name: "boards-postId",
    path: "/boards/:postId()",
    component: () => import("/usr/src/app/pages/boards/[postId].vue")
  },
  {
    name: "profiles-userName",
    path: "/profiles/:userName()",
    component: () => import("/usr/src/app/pages/profiles/[userName].vue")
  },
  {
    name: "classifieds-classifiedAdType-classifiedAdId",
    path: "/classifieds/:classifiedAdType()/:classifiedAdId()",
    meta: _91classifiedAdId_934TyvAKlPHDZInhiP9uEJd2CoVqfHZZfOobJ4AabaSLcMeta || {},
    component: () => import("/usr/src/app/pages/classifieds/[classifiedAdType]/[classifiedAdId].vue")
  }
]